import { MyLocation as MyLocationIcon } from '@mui/icons-material'
import { LoadingButton } from '@mui/lab'
import { Box, Fab } from '@mui/material'
import {
  ControlPosition,
  Map,
  MapControl,
  type MapEvent,
  useMap,
} from '@vis.gl/react-google-maps'
import { useSnackbar } from 'notistack'
import Geocode from 'react-geocode'
import { useNavigate } from 'react-router-dom'

import MarkerIcon from '~/assets/marker.png'
import PlacesAutocomplete from '~/components/PlacesAutocomplete'
import TitleBar from '~/components/TitleBar'
import { useAppDispatch, useAppSelector } from '~/redux/hooks'
import { setTimersByScreen, updateDraft } from '~/redux/slices/currentDraft'
import {
  normalizeDraftForSnapshot,
  saveSnapshotDraft,
} from '~/services/lifemap-survey-services'
import { useCurrentPosition } from '~/utils/hooks/useCurrentPosition'
import { screenValidation } from '~/utils/survey-utils'

Geocode.setApiKey(import.meta.env.VITE_MAP_API_KEY)

export default function Location() {
  const navigate = useNavigate()
  const dispatch = useAppDispatch()
  const map = useMap()

  const { enqueueSnackbar } = useSnackbar()
  const { t } = useTranslation()
  const { gettingCurrentPosition, getCurrentPosition } = useCurrentPosition()

  const currentDraft = useAppSelector(state => {
    if (!state.currentDraft) throw new Error('No currentDraft')
    return state.currentDraft
  })
  const currentSurvey = useAppSelector(state => {
    if (!state.currentSurvey) throw new Error('No currentSurvey')
    return state.currentSurvey
  })

  const parsedLat = Number(currentDraft.familyData.latitude)
  const parsedLng = Number(currentDraft.familyData.longitude)

  const defaultPosition: google.maps.LatLngLiteral = {
    lat: Number.isNaN(parsedLat)
      ? currentSurvey.surveyConfig.surveyLocation.latitude
      : parsedLat,
    lng: Number.isNaN(parsedLng)
      ? currentSurvey.surveyConfig.surveyLocation.longitude
      : parsedLng,
  }

  const markerPosition = useRef(defaultPosition)

  async function handleContinue() {
    const country = await getCountryFromLatLng(
      markerPosition.current.lat,
      markerPosition.current.lng,
    )

    const updatedDraft = {
      ...currentDraft,
      familyData: {
        ...currentDraft.familyData,
        latitude: markerPosition.current.lat,
        longitude: markerPosition.current.lng,
        ...(country ? { country } : {}),
      },
    }

    dispatch(updateDraft(updatedDraft))

    const normalizedDraft = normalizeDraftForSnapshot(updatedDraft)
    void saveSnapshotDraft(normalizedDraft)

    if (!currentSurvey.economicScreens?.questionsPerScreen.length) {
      navigate('/lifemap/begin-stoplight')
      return
    }

    navigate('/lifemap/economics/0')
  }

  async function handleSelect(
    latLng: google.maps.LatLng,
    viewport: google.maps.LatLngBounds,
  ) {
    if (!map) throw new Error('No map')
    map.fitBounds(viewport)

    const lat = latLng.lat()
    const lng = latLng.lng()

    markerPosition.current = { lat, lng }

    dispatch(
      updateDraft({
        ...currentDraft,
        familyData: {
          ...currentDraft.familyData,
          latitude: lat,
          longitude: lng,
        },
      }),
    )
  }

  async function getCountryFromLatLng(lat: number, lng: number) {
    try {
      const { results } = await Geocode.fromLatLng(
        lat.toString(),
        lng.toString(),
      )
      if (!results?.length) return null

      const element = results.find(result => result.types.includes('country'))
      if (!element) return null

      return element.address_components[0].short_name as string
    } catch (error) {
      console.error(error)
      return null
    }
  }

  function onIdle(event: MapEvent<unknown>) {
    const center = event.map.getCenter()
    if (!center) throw new Error('No center')

    markerPosition.current = { lat: center.lat(), lng: center.lng() }

    dispatch(
      updateDraft({
        ...currentDraft,
        familyData: {
          ...currentDraft.familyData,
          latitude: center.lat(),
          longitude: center.lng(),
        },
      }),
    )
  }

  const locateMe = useCallback(async () => {
    try {
      const currentPosition = await getCurrentPosition()

      const lat = currentPosition.coords.latitude
      const lng = currentPosition.coords.longitude
      markerPosition.current = { lat, lng }

      if (!map) throw new Error('No map')
      map.panTo({ lat, lng })

      dispatch(
        updateDraft({
          ...currentDraft,
          familyData: {
            ...currentDraft.familyData,
            latitude: lat,
            longitude: lng,
          },
        }),
      )
    } catch (error) {
      const isGeolocationError = error instanceof GeolocationPositionError
      if (!isGeolocationError) {
        return
      }

      switch (error.code) {
        case GeolocationPositionError.PERMISSION_DENIED:
          enqueueSnackbar(t('views.location.permissionDisabled'), {
            variant: 'error',
          })
          break
        case GeolocationPositionError.POSITION_UNAVAILABLE:
        case GeolocationPositionError.TIMEOUT:
          enqueueSnackbar(t('views.location.positionUnavailable'), {
            variant: 'error',
          })
          break
      }
    }
  }, [getCurrentPosition, map, dispatch, currentDraft, enqueueSnackbar, t])

  useEffect(() => {
    const validationResult = screenValidation(
      currentDraft,
      'LOCATION',
      currentSurvey,
    )

    if (validationResult) {
      enqueueSnackbar(t('validation.completionRequired'), { variant: 'error' })
      navigate(validationResult)
    }
  }, [currentDraft, enqueueSnackbar, navigate, t, currentSurvey])

  useEffect(() => {
    dispatch(setTimersByScreen('Location'))
  }, [dispatch])

  useEffect(() => {
    if (
      !currentDraft.familyData.latitude ||
      !currentDraft.familyData.longitude
    ) {
      void locateMe()
    }
  }, [currentDraft, locateMe])

  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', height: '100vh' }}>
      <TitleBar title={t('views.location.title')} progressBar />

      <Box sx={{ flexGrow: 1 }}>
        <Map
          mapId="164d8681d71b9cae"
          defaultZoom={18}
          defaultCenter={defaultPosition}
          style={{ height: '100%' }}
          clickableIcons={false}
          mapTypeControl={false}
          fullscreenControl={false}
          streetViewControl={false}
          zoomControlOptions={{ position: ControlPosition.RIGHT_TOP }}
          onIdle={onIdle}
        >
          <img
            src={MarkerIcon}
            style={{
              width: '40px',
              userSelect: 'none',
              zIndex: 1,
              position: 'absolute',
              top: '50%',
              left: '50%',
              transform: 'translate(-50%, -100%)',
              pointerEvents: 'none',
            }}
          />

          <MapControl position={ControlPosition.LEFT_BOTTOM}>
            <Fab
              sx={{ marginLeft: '20px', marginBottom: '14px' }}
              onClick={async () => {
                await locateMe()
              }}
              color="primary"
              size="medium"
              disabled={gettingCurrentPosition}
            >
              <MyLocationIcon />
            </Fab>
          </MapControl>

          <MapControl position={ControlPosition.TOP_LEFT}>
            <PlacesAutocomplete
              onPlaceSelect={place => {
                const geometry = place?.geometry
                if (!geometry?.location || !geometry?.viewport) return
                void handleSelect(geometry.location, geometry.viewport)
              }}
            />
          </MapControl>

          <MapControl position={ControlPosition.BOTTOM_CENTER}>
            <LoadingButton
              color="primary"
              variant="contained"
              onClick={async () => {
                await handleContinue()
              }}
              disabled={
                !currentDraft.familyData.latitude ||
                !currentDraft.familyData.longitude ||
                gettingCurrentPosition
              }
              sx={{ mb: '40px' }}
              loading={gettingCurrentPosition}
              size="large"
            >
              {t('general.continue')}
            </LoadingButton>
          </MapControl>
        </Map>
      </Box>
    </Box>
  )
}
